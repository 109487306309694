<template>
  <v-container fluid>
    <v-overlay v-if="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container>
      <SortableListMenu
        v-for="menuData in tmpMenuDatas"
        :key="menuData.group.id"
        v-bind:group-id="menuData.group.id"
        v-bind:subtitle="menuData.group.name"
        v-bind:datas="menuData.menus"
        :handle-add="add"
        :handle-edit="edit"
        :deletable="false"
        :handle-del="del"
        :sortable="menuData.group.sortable"
        :handle-on-sort-move="onSortMove"
        :handle-on-sort-end="onSortEnd"
      />
    </v-container>

    <!-- 部品 -->
    <v-container>
      <!-- TODO 用ダイアログ -->
      <v-dialog v-model="todoInfo.show" :max-width="todoInfo.maxWidth">
        <v-card>
          <v-card-title>TODO</v-card-title>
          <v-card-text class="text-center" v-html="todoInfo.contents" />
        </v-card>
      </v-dialog>

      <!-- snack -->
      <v-snackbar
        v-model="snack"
        :timeout="3000"
        :color="snackColor"
        outlined
        right
      >
        {{ snackText }}
      </v-snackbar>
    </v-container>
  </v-container>
</template>

<script>
import { auth } from "../../plugins/firebase";
import { mapGetters, mapActions } from "vuex";
import { GROUP_ID_DEFAULT } from "../../constants";

export default {
  name: "MenuList",
  components: {
    SortableListMenu: () => import("./components/list/SortableListMenu"),
  },
  data: () => ({
    tmpMenuDatas: [],

    // 確認ダイアログ
    todoInfo: {
      show: false,
      maxWidth: 300,
      contents: "TODO",
    },
    // snack
    snack: false,
    snackColor: "",
    snackText: "",
  }),
  computed: {
    ...mapGetters(["progress", "menus"]),
  },
  watch: {
    menus: {
      handler: function (newValue) {
        this.tmpMenuDatas = this.createTmpMenuDatas(newValue);
      },
    },
  },
  async mounted() {
    this.fetchMenus();
  },
  methods: {
    ...mapActions(["fetchMenus", "deleteMenu", "updateMenuSort"]),
    logout: async function () {
      await auth.signOut().catch((e) => {
        console.error(e);
      });
      this.$router.push("/login");
    },
    createSubHeaderText(group) {
      console.log(`createSubHeaderText: ${JSON.stringify(group)}`);
      return group.name;
    },
    createTmpMenuDatas(menuDatas) {
      if (!menuDatas || menuDatas.length == 0) {
        return [];
      }
      // menus を部品用にカスタム
      const tmpMenuDatas = menuDatas.map((data) => {
        const menus = data.menus;
        const tmpMenus = menus.map((menu) => {
          // 部品に必要な要素のみ
          const newData = {};
          newData.id = menu.menuId;
          newData.name = menu.name;
          newData.image_url = menu.image_url;
          newData.amountBaseEx = menu.amountBaseEx;
          newData.discountStatus = menu.discountStatus;
          newData.provideStatus = menu.provideStatus;
          newData.publish = menu.publish;
          return newData;
        });
        data.menus = tmpMenus;
        return data;
      });
      return tmpMenuDatas;
    },
    add(groupId) {
      // 画面遷移
      if (groupId === GROUP_ID_DEFAULT) {
        this.$router.push(`/menu/new`);
      } else {
        this.$router.push(`/menu/new?groupId=${groupId}`);
      }
    },
    edit(menu) {
      // 画面遷移
      this.$router.push(`/menu/edit/${menu.id}`);
    },
    del(delInfo) {
      console.log(`del: ${JSON.stringify(delInfo)}`);
      this.executeDeleteMenu(delInfo.id);
    },
    async executeDeleteMenu(menuId) {
      if (!menuId) {
        throw Error("not implemented");
      }
      await this.deleteMenu({ menuId: menuId });

      // メニュー一覧 再取得
      this.fetchMenus();
    },
    onSortMove(updatedItems) {
      //TODO ソート終了 (DB 更新？)
      console.log(`onSortMove: ${JSON.stringify(updatedItems)}`);
    },
    async onSortEnd(updatedItems) {
      //TODO DB 更新 (group.sort)
      console.log(`onSortEnd: ${JSON.stringify(updatedItems)}`);

      const groupId = updatedItems.groupId;
      const items = updatedItems.items.map((item, index) => {
        return {
          menuId: item.id,
          sort: index,
        };
      });
      console.log(`items(sort)=${JSON.stringify(items)}`);

      await this.updateMenuSort({ groupId: groupId, menus: items });
      this.fetchMenus();

      this.snack = true;
      this.snackColor = "success";
      this.snackText = "表示順を更新しました";
    },
  },
};
</script>
